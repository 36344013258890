import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Image from "../atoms/image"
import useMedia from "../customHooks/useMedia"

const People = ({ filename, name, position, description, link = "" }) => {
  const isMobile = useMedia("(max-width: 767px)")
  return (
    <PeopleWrap>
      <Flex>
        {link ? (
          <ImageLinkWrap>
            <Link to={link}>
              <Image
                filename={filename}
                style={{
                  width: 150,
                  height: 150,
                  borderRadius: "50%",
                }}
              />
            </Link>
          </ImageLinkWrap>
        ) : (
          <ImageWrap>
            <Image
              filename={filename}
              style={{
                width: 150,
                height: 150,
                borderRadius: "50%",
              }}
            />
          </ImageWrap>
        )}
        <PeopleDesc>
          {link ? (
            <Link to={link}>
              <PeopleNameLink>{name}</PeopleNameLink>
              {position && <PeoplePosition>{`${position}`}</PeoplePosition>}
              <p>{description}</p>
            </Link>
          ) : (
            <>
              <PeopleName>{name}</PeopleName>
              {position && <PeoplePosition>{`${position}`}</PeoplePosition>}
              <p>{description}</p>
            </>
          )}
        </PeopleDesc>
      </Flex>
    </PeopleWrap>
  )
}
export default People

const PeopleWrap = styled.li`
  margin-top: 40px;
`

const PeopleDesc = styled.li`
  margin-left: 32px;

  /* タブレットも含む */
  @media screen and (max-width: 768px) {
    margin-left: 0px;
    margin-bottom: 20px;
  }
`

const ImageWrap = styled.li`
  /* タブレットも含む */
  @media screen and (max-width: 768px) {
    margin: 0 auto;
  }
`

const ImageLinkWrap = styled.li`
  /* タブレットも含む */
  @media screen and (max-width: 768px) {
    margin: 0 auto;
  }

  cursor: pointer;
  :hover {
    color: white;
    opacity: 0.8;
  }
  :active {
    opacity: 0.8;
  }
`

const PeopleName = styled.span`
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 0.18rem;

  /* タブレットも含む */
  @media screen and (max-width: 768px) {
    display: block;
    text-align: center;
    margin: 14px 0;
  }
`

const PeopleNameLink = styled(PeopleName)`
  text-decoration: underline;
`

const PeoplePosition = styled(PeopleName)`
  font-size: 1rem;
  font-weight: 700;
  margin-left: 1rem;
`

const Flex = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  /* タブレットも含む */
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
`
