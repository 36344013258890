import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Image from "../atoms/image"
import useMedia from "../customHooks/useMedia"

const Title = () => {
  const isMobile = useMedia("(max-width: 767px)")
  return (
    <Section>
      <TitleMain>ぴんとくるくる浄土</TitleMain>
      <Image
        filename={`title.png`}
        style={{ width: isMobile ? 200 : 350, margin: "0 auto" }}
      />
      <TitleDesc>言葉と実践を通じて「身体と道具をつなぐもの」を探る</TitleDesc>
      <Image
        filename="philosophy.jpg"
        style={{ maxWidth: 900, height: 275, margin: "0 auto" }}
      />
      <PhilosophyDesc>
        「ぴんとくるくる浄土」は Juggling Unit
        ピントクルが日々創作しているスタジオ、浄土複合にておこなう小さな塾です。
        <br />
        日本・世界で行われてきた様々なジャグリングを振り返り、整理する座学。
        <br />
        自分だけの道具と「道具とのつながり方」を模索し、作品に仕上げる演習。
        <br />
        その二つを通じて、「身体と道具をつなぐもの」を探ります。
      </PhilosophyDesc>
      <Left>
        <Link to="/about/">&gt; ぴんとくるくる浄土とは</Link>
      </Left>
    </Section>
  )
}
export default Title

const Section = styled.section`
  margin: 40px 0 60px;
  padding: 0 14px;

  /* タブレットも含む */
  @media screen and (max-width: 768px) {
    margin: 100px 0 60px;
  }
`

const TitleMain = styled.h1`
  text-align: center;
`

const TitleDesc = styled.p`
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
  margin: 80px 0 120px;

  /* タブレットも含む */
  @media screen and (max-width: 768px) {
    font-size: 1.125rem;
  }
`

const PhilosophyDesc = styled.p`
  margin: 20px 0 30px;
`

const Left = styled.div`
  text-align: right;
  text-decoration: underline;
`
