import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Image from "../atoms/image"
import useMedia from "../customHooks/useMedia"
import AllPeople from "./allpeople"
import People from "./people"

const WorkShop = () => {
  const isMobile = useMedia("(max-width: 767px)")
  return (
    <Section>
      <Hr />
      <TitleSub>WORKSHOP</TitleSub>
      <WorkShopTitle>Season2「ジャグリングの出発点」</WorkShopTitle>
      <Image filename="season2.jpg" style={{ maxWidth: 900 }} />
      <Part>
        <PartTitle>内容</PartTitle>
        <ActivityList>
          <ActivityTitle>1. ジャグリングワークショップ</ActivityTitle>
          <Activity>
            ワークショップは、座学と演習で構成されます。 <br />
            <br />
            座学は、ZoomとScrapboxを利用したオンラインのゼミ形式です。
            <br />
            前半では「ジャグリングを見る/見られるとは？」などのテーマに沿って、ジャグリングの歴史を振り返り、後半ではそのテーマを起点にしながら、ディスカッションをおこないます。
            <br />
            <br />
            演習では、座学での振り返りを活かしつつ、自分だけの「道具とのつながり方」を模索します。
            <br />
            拠点として浄土複合を活用し、お互いのフィードバックを受けながら成果発表会にむけて作品の創作をします。
          </Activity>
          <ActivityTitle>2. 成果発表会</ActivityTitle>
          <Activity>
            ワークショップ受講者は2日間にわたって、成果作品を発表します。
            <br />
            1日目は記録映像の撮影とインタビュー、2日目は映像配信をおこないます。
            <br />
          </Activity>
          <ActivityTitle>3. ワークショップ後</ActivityTitle>
          <Activity>
            ワークショップ、成果発表会の様子をアーカイブとして公開します。
          </Activity>
          <Activity>
            ※新型コロナウイルス対策に関して <br />
            会場内では手指の消毒、マスクの着用、換気を徹底し、２m目安の対人距離を確保する等の対策に努めます。
            <br />
          </Activity>
        </ActivityList>
      </Part>

      <Part>
        <PartTitle>スケジュール</PartTitle>
        <STable>
          <STrHeader>
            <STh>日程</STh>
            <STh>テーマ</STh>
            <STh></STh>
          </STrHeader>
          <STBody>
            <STr>
              <STdYear colSpan={3}>2020年</STdYear>
            </STr>
            <STr>
              <STd>8月10日(月) </STd>
              <STd>なぜジャグリングをするのか？</STd>
              <STd>座学</STd>
            </STr>
            <STr>
              <STd>8月15日(土) </STd>
              <STd>ジャグリングの魅力を語ろう</STd>
              <STdEnshu>演習</STdEnshu>
            </STr>
            <STr>
              <STd>8月21日(金) </STd>
              <STd>ジャグリングの「技術」とは何か？</STd>
              <STd>座学</STd>
            </STr>
            <STr>
              <STd>8月29日(土) </STd>
              <STd>道具を持ち寄る</STd>
              <STdEnshu>演習</STdEnshu>
            </STr>
            <STr>
              <STd>9月4日(金) </STd>
              <STd>ジャグリングを見る／見られるとは？</STd>
              <STd>座学</STd>
            </STr>
            <STr>
              <STd>9月12日(土) </STd>
              <STd>「見せる」を探す</STd>
              <STdEnshu>演習</STdEnshu>
            </STr>
            <STr>
              <STd>9月18日(金) </STd>
              <STd>ジャグリングとは？</STd>
              <STd>座学</STd>
            </STr>
            <STr>
              <STd>10月3日(土) </STd>
              <STd>中間発表会</STd>
              <STdEnshu>演習</STdEnshu>
            </STr>
            <STr>
              <STd>10月24日(土)</STd>
              <STd>成果発表会(記録)</STd>
              <STdHappyo>発表</STdHappyo>
            </STr>
            <STr>
              <STd>10月25日(日)</STd>
              <STd>成果発表会(映像配信)</STd>
              <STdHappyo>発表</STdHappyo>
            </STr>
          </STBody>
        </STable>
      </Part>
      <Part>
        <PartTitle>成果発表会</PartTitle>
        <p>
          日程: 2020/10/25(日) <br />
          <br />
          12:30- オープニング <br />
          「ぴんとくるくる浄土とは？」(中西みみず) <br />
          13:00- 染谷樹
          <br />
          14:00- 山下湧志朗
          <br />
          15:00- 貫井洋介
          <br />
          <br />
          {isMobile ? (
          <iframe width="280" height="150" src="https://www.youtube.com/embed/DmK395ZlDGo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        ) : (
          <iframe width="560" height="315" src="https://www.youtube.com/embed/DmK395ZlDGo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        )}
          <br />
        </p>
      </Part>
      <Part>
        <PartTitle>参加者</PartTitle>
        <ul>
          <People
            filename="someya.jpg"
            name="染谷樹 『もう一度』"
            position=""
            description={`1993年生まれ。2012年にシガーボックスを始め、現在は靴や木枠といった⽇⽤品を扱うジャグリングを行う。台湾Future
            Circus Lab. open stage、フラトレス第4回公演、秘密基地vol.10、CwC第7回公演等出演。`}
            link="/someya"
          />
          <People
            filename="yamashita_yu.jpg"
            name="山下湧志朗 『自作道具のPoC』"
            position=""
            description={` 京都大道芸倶楽部 Juggling
            Donuts所属のクラブジャグラー。道具の特性を活かしたいという気持ちが強い。`}
            link="/yamashita_yu"
          />
          <People
            filename="nukii.jpg"
            name="貫井洋介 『不可能な赤』"
            position=""
            description={`1995年生まれ。2012年にジャグリングと出会う。ボールジャグラー。過去には、JJF
            2017 Championship 決勝出場や、Circus without Circle
            第7回公演出演など。`}
            link="/nukii"
          />
        </ul>
      </Part>
      <Part>
        <PartTitle>講師</PartTitle>
        <ul>
          <People
            filename="mimizu.jpg"
            name="中西みみず"
            position="演習担当"
            description={`1991年岐阜県生まれ。ジャグラー、演出家。 Juggling Unit ピントクル代表。主な作品に、紙幣と本を道具とした「Juggling is Free」(彩の国さいたま芸術劇場、2018年)。パソコンを道具とした「404 Not Found」(こまばアゴラ劇場、2019年)など。`}
          />
          <People
            filename="yamashita.jpg"
            name="山下耕平"
            position="座学担当"
            description={`1991年岐阜県生まれ。ジャグラー。Juggling Unit ピントクル所属。京都大学大学院文学研究科博士課程在籍中。20世紀後半になって初めて広まった「ジャグリング」という新たな文化の来歴と限界を考えたい。`}
          />
        </ul>
      </Part>
      {/* <Part>
        <PartTitle>Place</PartTitle>
        <span>浄土複合</span>
        <p>
          ※当イベントは「来場不可」となりますので会場にお越しいただくことはできません。
          イベント後にテキストや動画にて発表の様子を公開致します。
          詳細はこちらをご覧ください。
        </p>
      </Part>
      <Part>
        <PartTitle>Report</PartTitle>
        <ul>
          <li>
            <span>レポート1</span>
          </li>
          <li>
            <span>レポート2</span>
          </li>
        </ul>
      </Part> */}
      <Part>
        <CenterBox>
          <WorkShopSupplement>
            主催｜Juggling Unit ピントクル
          </WorkShopSupplement>
          <WorkShopSupplement>協力｜浄土複合</WorkShopSupplement>
          <WorkShopSupplementMore>
            本事業は「新型コロナウイルス感染症の影響に伴う京都市⽂化芸術活動緊急奨励⾦」の採択事業です。
          </WorkShopSupplementMore>
        </CenterBox>
      </Part>
    </Section>
  )
}
export default WorkShop

const Section = styled.section`
  margin-bottom: 60px;
  padding: 0 14px;
`

const Hr = styled.hr`
  height: 1px;
  background-color: #9a9a9a;
  width: 100%;
  border: none;
`

const TitleSub = styled.h2`
  text-align: center;
  margin: 8px auto 40px;
`

const WorkShopTitle = styled.h3`
  font-size: 2.25rem;
  margin: 8px auto 40px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`

const Part = styled.div`
  margin-top: 40px;
`

const PartTitle = styled.h4`
  text-align: center;
  margin-bottom: 20px;
`

const CenterBox = styled.div`
  margin-top: 80px;
  /* max-width: 450px; */
  /* margin: 0 auto; */
`

const WorkShopSupplement = styled.span`
  display: block;
`

const WorkShopSupplementMore = styled.span`
  font-size: 0.875rem;
`

const ActivityList = styled.div``

const ActivityTitle = styled.span`
  font-size: 1.125rem;
  font-weight: 700;
  display: block;
  margin-top: 14px;
`
const Activity = styled.p`
  margin: 14px 0 0;
`

const STable = styled.table`
  margin: 0 auto;
  border-collapse: collapse;
`

const STBody = styled.tbody`
  border-top: 5px solid #404040;
  border-bottom: 4px solid #404040;
`

const STh = styled.th`
  margin: 14px 0 0;
`

const STr = styled.tr`
  height: 2rem;
`

const STrHeader = styled.tr`
  border-top: 2px solid #959595;
`

const STd = styled.td`
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #d5d5d5;
`

const STdYear = styled(STd)`
  font-size: 1rem;
`

const STdEnshu = styled(STd)`
  color: #ff0000;
`

const STdHappyo = styled(STd)`
  background-color: #ff0;
`
