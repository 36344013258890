import React from "react"
import Layout from "../components/theme/layout"
import SEO from "../components/organisms/seo"
import Title from "../components/organisms/title"
import News from "../components/organisms/news"
import WorkShop from "../components/organisms/workshop"
import { graphql, PageProps } from "gatsby"

const IndexPage: React.FC<PageProps> = ({ data }) => {
  const image = data.images.edges.find(n => {
    return n.node.relativePath.includes("season2.jpg")
  })
  const domain = "https://kurkuru-jodo.juggling-pintcle.com"
  return (
    <Layout>
      <SEO
        lang="ja"
        title="ぴんとくるくる浄土 - Home"
        description="言葉と実践を通じて「身体と道具をつなぐもの」を探る"
        picUrl={domain + image.node.childImageSharp.sizes.src}
      />
      <Title />
      {/* <News /> */}
      <WorkShop />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    images: allFile {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            sizes(maxWidth: 800) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }
`
